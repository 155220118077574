import classNames from 'classnames';
import { FC } from 'react';

interface StatItemProps {
  value: string;
  label: string;
  moreInfo?: React.ReactNode;
  aosDelay?: number;
  type?: 'cards' | 'flat';
  className?: string;
}

const StatItem: FC<StatItemProps> = ({ value, label, aosDelay = 0, type, moreInfo, className }) => {
  let wrapperClassName = 'bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl';
  let valueClassName = 'font-red-hat-display text-3xl font-black tracking-tighter mb-1';
  let labelClassName = 'text-gray-600 dark:text-gray-400';
  let moreInfoClassName = 'block';

  if (type === 'flat') {
    wrapperClassName = 'mb-5 sm:mb-0 border-transparent sm:border-r border-gray';
    valueClassName = 'font-red-hat-display text-3xl font-black tracking-tighter mb-1';
    moreInfoClassName = 'block';
  }
  return (
    <div className={classNames(wrapperClassName, className)} data-aos="fade-down" data-aos-delay={aosDelay}>
      <div className={valueClassName}>{value}</div>
      <div className={labelClassName}>{label}</div>
      <small className={moreInfoClassName}>{moreInfo}</small>
    </div>
  );
};

interface StatsSectionProps {
  items: StatItemProps[];
  type?: StatItemProps['type'];
}

const StatsSection: FC<StatsSectionProps> = ({ items, type = 'flat' }) => (
  <section className="relative">
    <div
      className="absolute bottom-0 left-0 right-0 h-128 bg-gradient-to-t from-gray-100 to-white pointer-events-none -z-10 dark:hidden"
      aria-hidden="true"
    ></div>
    <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div className="pb-12 md:pb-20">
        <div
          className={classNames('grid grid-cols-2 md:grid-cols-5 text-center justify-center gap-5', {
            'gap-4 lg:gap-6': type === 'cards',
          })}
        >
          {items.map((item, index) => (
            <StatItem
              key={index}
              value={item.value}
              label={item.label}
              moreInfo={item.moreInfo}
              aosDelay={item.aosDelay}
              type={type}
              className={index === items.length - 1 ? 'border-r-0' : ''}
            />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default StatsSection;
