import Image from 'next/image';
import React from 'react';

export type TestimonialProps = {
  imageSrc?: string;
  altText?: string;
  quote: string | React.ReactNode;
  author?: string;
  company?: string;
  type?: 'cards' | 'center' | 'skewed' | 'stars' | 'casual';
};

const Testimonial: React.FC<TestimonialProps> = ({ imageSrc, altText, quote, author, company, type = 'cards' }) => {
  if (type === 'cards') {
    return (
      <div className="rounded-3xl bg-orange-50 p-5">
        {(author || company || imageSrc) && (
          <div className="mb-4 flex items-start">
            {imageSrc && <Image className="mr-3 shrink-0 rounded-full" src={imageSrc} width={48} height={48} alt={altText || ''} />}
            {(author || company) && (
              <div>
                <div className="font-hkgrotesk font-extrabold text-orange-800">{author}</div>
                <a className="font-hkgrotesk font-medium text-orange-500 hover:underline">{company}</a>
              </div>
            )}
          </div>
        )}
        <blockquote className="whitespace-pre-line text-orange-900">{quote}</blockquote>
      </div>
    );
  }

  if (type === 'center') {
    return (
      <article className="text-center">
        {imageSrc && (
          <div className="relative mb-4 inline-flex flex-col">
            <Image className="rounded-full" src={imageSrc} alt={altText || ''} width={56} height={56} />
            <svg className="absolute right-0 top-0 -mr-8 mt-1" width="27" height="12" xmlns="http://www.w3.org/2000/svg">
              <path
                className="fill-current text-primary"
                d="M2.785 5.334C2.538 5.5-.2 2.944.011 2.646.826 1.483 2.183.836 3.62.5 5.064.158 6.582.117 7.92-.02c.017-.002.098.153.088.166-1.763 2.018-3.223 3.836-5.221 5.188zm3.676 6.519c-.862.184-1.937-3.403-1.07-3.711 3.422-1.22 7.078-1.671 10.728-1.766 3.655-.096 7.304.162 10.866.32.044.002.06.177.018.187-6.938 1.634-13.691 3.504-20.542 4.97z"
              />
            </svg>
          </div>
        )}
        <blockquote className="whitespace-pre-line text-xl text-gray-600 dark:text-gray-400">{quote}</blockquote>
        {(author || company) && (
          <div className="font-red-hat-display mt-2 font-bold">
            <cite className="not-italic">{author}</cite>, <span className="text-secondary transition duration-150 ease-in-out">{company}</span>
          </div>
        )}
      </article>
    );
  }
  if (type === 'skewed') {
    return (
      <div className="rounded-xl border border-secondary bg-teal-50 p-5 transition duration-150 ease-in-out odd:rotate-1 even:-rotate-1 hover:rotate-0">
        <div className="flex items-center space-x-5">
          <div className="relative shrink-0">
            {imageSrc && <Image className="rounded-full" src={imageSrc} width={102} height={102} alt={altText || ''} />}
            <svg className="absolute right-0 top-0 fill-primary" width={26} height={17} xmlns="http://www.w3.org/2000/svg">
              <path d="M0 16.026h8.092l6.888-16h-4.592L0 16.026Zm11.02 0h8.092L26 .026h-4.65l-10.33 16Z" />
            </svg>
          </div>
          <figure>
            <blockquote className="m-0 whitespace-pre-line pb-1 text-lg font-bold">{quote}</blockquote>
            {(author || company) && (
              <figcaption className="text-sm font-medium">
                {author} <span className="text-secondary hover:underline">{company}</span>
              </figcaption>
            )}
          </figure>
        </div>
      </div>
    );
  }

  if (type === 'stars') {
    return (
      <article className="flex h-full flex-col items-center text-center" data-aos="fade-up">
        <header className="mb-3">
          {imageSrc && <Image className="inline-flex rounded-full" src={imageSrc} width={40} height={40} alt={altText || ''} />}
          <div className="mt-4">
            <div className="flex space-x-1">
              <button>
                <span className="sr-only">1 star</span>
                <svg className="h-4 w-4 fill-amber-400" viewBox="0 0 16 16">
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
              </button>
              <button>
                <span className="sr-only">2 stars</span>
                <svg className="h-4 w-4 fill-amber-400" viewBox="0 0 16 16">
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
              </button>
              <button>
                <span className="sr-only">3 stars</span>
                <svg className="h-4 w-4 fill-amber-400" viewBox="0 0 16 16">
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
              </button>
              <button>
                <span className="sr-only">4 stars</span>
                <svg className="h-4 w-4 fill-amber-400" viewBox="0 0 16 16">
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
              </button>
              <button>
                <span className="sr-only">5 stars</span>
                <svg className="h-4 w-4 fill-amber-400" viewBox="0 0 16 16">
                  <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                </svg>
              </button>
            </div>
          </div>
        </header>
        <div className="mb-3 grow">
          <blockquote className="whitespace-pre-line italic text-slate-500">{quote}</blockquote>
        </div>
        {(author || company) && (
          <footer className="text-sm font-medium text-slate-500">
            <cite className="text-secondary transition duration-150 ease-in-out hover:text-white">{author}</cite> -{' '}
            <span className="text-slate-300">{company}</span>
          </footer>
        )}
      </article>
    );
  }

  if (type === 'casual') {
    return (
      <div className="bgd-gray-800 flex h-full flex-col p-6" data-aos="fade-up">
        {imageSrc && (
          <div className="relative mb-4 inline-flex flex-col">
            <Image className="rounded-full" src={imageSrc} width={48} height={48} alt={altText || ''} />
            <svg className="absolute right-0 top-0 -mr-3 h-5 w-6 fill-current text-primary" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 13.517c0-2.346.611-4.774 1.833-7.283C3.056 3.726 4.733 1.648 6.865 0L11 2.696C9.726 4.393 8.777 6.109 8.152 7.844c-.624 1.735-.936 3.589-.936 5.56v4.644H0v-4.531zm13 0c0-2.346.611-4.774 1.833-7.283 1.223-2.508 2.9-4.586 5.032-6.234L24 2.696c-1.274 1.697-2.223 3.413-2.848 5.148-.624 1.735-.936 3.589-.936 5.56v4.644H13v-4.531z" />
            </svg>
          </div>
        )}
        <blockquote className="grow whitespace-pre-line text-lg text-gray-400">{quote}</blockquote>
        {(author || company) && (
          <div className="textd-gray-700 borderd-gray-700 mt-6 border-t pt-5 font-medium">
            <cite className="textd-gray-200 not-italic">{author}</cite> - <span className="text-primary">{company}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <article className="flex h-full flex-col bg-gray-50 p-6" data-aos="fade" data-aos-anchor="[data-aos-id-testimonials]">
      <header className="mb-4">
        {imageSrc && <Image className="shrink-0 rounded-full" src={imageSrc} width={48} height={48} alt={altText || ''} />}
      </header>
      <div className="mb-3 grow">
        <blockquote className="whitespace-pre-line text-gray-500">{quote}</blockquote>
      </div>
      {(author || company) && (
        <footer className="text-sm text-gray-500">
          <cite className="text-gray-400">{author}</cite> -{' '}
          <span className="hover:text-primary-focus font-medium text-primary transition duration-150 ease-in-out">{company}</span>
        </footer>
      )}
    </article>
  );
};

export default Testimonial;
