import classNames from 'classnames';
import React from 'react';
import Skeleton from './Skeleton';

type SectionProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  bgColor?: string;
  center?: boolean;
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

const Section: React.FC<SectionProps> = ({ title, subtitle, bgColor, className, loading, center = true, children, size = 'xl', ...props }) => {
  return (
    <section className={classNames('mx-auto px-4 py-8 text-gray-900 dark:text-white lg:px-12 lg:py-16', bgColor, className)} {...props}>
      <div
        className={classNames('mx-auto ', {
          'max-w-screen-sm': size === 'sm',
          'max-w-screen-md': size === 'md',
          'max-w-screen-lg': size === 'lg',
          'max-w-screen-xl': size === 'xl',
        })}
      >
        {title && (
          <h2
            className={classNames('mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl', {
              'text-center': center,
            })}
          >
            {loading ? <Skeleton className="h-8 w-40" /> : title}
          </h2>
        )}
        {subtitle && (
          <p
            className={classNames({
              'text-center': center,
            })}
          >
            {loading ? <Skeleton className="mt-5 h-5 w-80" rows={2} /> : subtitle}
          </p>
        )}
        {loading ? <Skeleton className="mt-5 h-10 w-80" /> : children}
      </div>
    </section>
  );
};

export default Section;
