import React from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import type { Graph, Thing } from 'schema-dts';
import { useTranslation } from 'modules/i18n';

const { publicRuntimeConfig } = getConfig();
const websiteUrl = publicRuntimeConfig.siteUrl;
const websiteName = publicRuntimeConfig.siteName;
const twitterUser = publicRuntimeConfig.twitterHandle;
const type = 'website';

export default function CommonHead({
  title,
  description: pageDescription,
  image = `${websiteUrl}/images/social/facebook-card.webp`,
  imageWidth = '1312',
  imageHeight = '485',
  url,
  children,
  alternateUrls,
  noTitleSuffix = false,
  microdata = [],
  noIndex = false,
}: {
  title?: string;
  description?: string | null;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  url?: string;
  noTitleSuffix?: boolean;
  alternateUrls?: { [key: string]: string };
  children?: any;
  noIndex?: boolean;
  microdata?: Thing[];
}) {
  const router = useRouter();
  const { t } = useTranslation();
  const titleWithSuffix = [title || t('common.seo:default.title'), noTitleSuffix ? undefined : websiteName].filter(Boolean).join(' - ');
  const description = pageDescription || t('common.seo:default.description');
  const currentUrl = url || router.asPath;

  const sameAs = [''];

  const graph: Graph = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        url: websiteUrl,
        logo: `${websiteUrl}/logo/hhc.jpg`,
        sameAs,
      },
      {
        '@type': 'WebSite',
        url: websiteUrl,
        sameAs,
        // potentialAction: {
        //   '@type': 'SearchAction',
        //   target: `${websiteUrl}?q={q}`,
        //   // @ts-ignore
        //   'query-input': 'name=q',
        // },
      },
      ...microdata,
    ],
  };

  const httpImage = image.startsWith('http') ? image : `${websiteUrl}${image}`;

  return (
    <Head>
      <title>{titleWithSuffix}</title>
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="description" content={description} />
      <link rel="icon" href="/favicon.ico" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      {/* <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon-180x180.png" /> */}
      {/* <link rel="icon" type="image/png" href="/icons/android-chrome-192x192.png" sizes="192x192" /> */}

      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/icons/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/icons/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/icons/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/icons/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/icons/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/icons/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/icons/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/icons/apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="/icons/favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="/icons/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="/icons/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/icons/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="/icons/favicon-128.png" sizes="128x128" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="/icons/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/icons/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/icons/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/icons/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/icons/mstile-310x310.png" />

      <link rel="manifest" href="/manifest.json" />
      <link rel="alternate" href={`${websiteUrl}${currentUrl}`} hrefLang="x-default" />
      {(router?.locales || []).map((locale) => (
        <link
          key={`alternate_${locale}`}
          rel="alternate"
          href={`${websiteUrl}${locale === router.defaultLocale ? '' : `/${locale}`}${(alternateUrls || {})[locale] || currentUrl}`}
          hrefLang={locale}
        />
      ))}
      <link rel="preconnect" crossOrigin="anonymous" href="https://www.google-analytics.com" />
      <link rel="preconnect" crossOrigin="anonymous" href="https://www.googletagmanager.com" />
      {/* TWITTER https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {twitterUser && <meta name="twitter:site" content={twitterUser} />}
      {/* OPENGRAPH https://ogp.me/ */}
      <meta property="og:title" content={titleWithSuffix} />
      {currentUrl && <meta property="og:url" content={`${websiteUrl}${currentUrl}`} />}
      <meta property="og:type" content={type} />
      {description && <meta property="og:description" content={description} />}
      {websiteName && <meta property="og:site_name" content={websiteName} />}
      <link
        rel="canonical"
        href={`${websiteUrl}${router.locale === router.defaultLocale ? '' : `/${router.locale}`}${currentUrl === '/' ? '' : currentUrl}`}
      />

      <meta itemProp="image" content={httpImage} />
      <meta property="og:image" content={httpImage} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta name="twitter:image" content={httpImage} />

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(graph) }} />
      {children}
    </Head>
  );
}
