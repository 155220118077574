import React from 'react';
import classNames from 'classnames';

type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
  rows?: number;
};

const Skeleton: React.FC<SkeletonProps> = ({ className, rows = 1, ...props }) => {
  return <span className={classNames('skeleton block bg-darklight', className)} {...props} />;
};

export default Skeleton;
