import classNames from 'classnames';
import SubscribeButton from 'modules/Billing/data-components/SubscribeButton';
import useUser from 'modules/User/hooks/useUser';
import Button from 'modules/common/components/Button';
import React from 'react';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';
import PlanBadge from './PlanBadge';

type PricingProps = React.HTMLAttributes<HTMLDivElement> & {
  withSubscribeButton?: boolean;
};

const PricingItem = ({
  plan,
  description,
  price,
  priceSmall,
  features,
  warranty,
  highlighted,
  action,
}: {
  plan: React.ComponentProps<typeof PlanBadge>['type'];
  description?: React.ReactNode;
  price: React.ReactNode;
  priceSmall: React.ReactNode;
  warranty?: React.ReactNode;
  action?: React.ReactNode;
  highlighted?: boolean;
  features: { title: React.ReactNode; included?: boolean }[];
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border  shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white',
        {
          'border-gray-100': !highlighted,
          'border-primary': highlighted,
        }
      )}
    >
      <div className="flex-1">
        <h3 className="mb-4 text-2xl font-semibold">
          {plan === 'none' ? (
            <PlanBadge type={'none'} label="Sans Headlinker" />
          ) : (
            <>
              Headlinker <PlanBadge type={plan} />
            </>
          )}
        </h3>
        <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>
        <div className="flex justify-center items-baseline my-8">
          <span className="mr-2 text-5xl font-extrabold">{price}</span>
          <span className="text-gray-500 dark:text-gray-400">{priceSmall}</span>
        </div>
        <ul role="list" className="mb-8 space-y-4 text-left">
          {features.map((feature, index) => (
            <li key={`feature_${index}`} className="flex items-center space-x-3">
              {feature.included ? <MdCheckCircle className="fill-success" /> : <MdRemoveCircle className="fill-error" />}
              <span>{feature.title}</span>
            </li>
          ))}
        </ul>
        <div>{warranty}</div>
      </div>
      {action && <div className="mt-3">{action}</div>}
    </div>
  );
};

const Pricing: React.FC<PricingProps> = ({ className, withSubscribeButton = true, ...props }) => {
  const { hasPermission } = useUser();
  return (
    <div
      className={classNames(
        'space-y-8 lg:grid sm:gap-6 xl:gap-10 lg:space-y-0',
        {
          'lg:grid-cols-3': !withSubscribeButton,
          'lg:grid-cols-2': withSubscribeButton,
        },
        className
      )}
      {...props}
    >
      {!withSubscribeButton && (
        <PricingItem
          plan="none"
          price="0€"
          priceSmall="/mois"
          features={[
            { title: 'Pas membre d’une communauté de confiance' },
            { title: 'Pas de café virtuel pour networker' },
            { title: 'Pas de slack' },
            { title: 'Pas de placement supplémentaire' },
            { title: 'Trimoji à 300€/mois' },
            { title: 'Pas d’ateliers' },
            { title: 'Formation de Kemra à 2 200€' },
            { title: 'Pas d’audit de ton activité' },
          ]}
          warranty={
            <>
              👎 <strong>Tout seul dans ton coin</strong>. Dommage pour toi.
            </>
          }
        />
      )}
      <PricingItem
        plan="standard"
        price="0€"
        priceSmall="/mois"
        features={[
          { title: 'Membre d’une communauté de confiance', included: true },
          { title: '☕ Café virtuel pour networker', included: true },
          { title: 'Super slack', included: true },
          {
            title: (
              <>
                <strong>10% de frais</strong> en cas de succès
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Trimoji <strong>Réduction de 50€/mois</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Ateliers à <strong>90€/séance</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Formation Kemra <strong>Réduction de 150€</strong>
              </>
            ),
            included: true,
          },
          { title: 'Pas d’audit de ton activité' },
        ]}
        warranty={
          <>
            👍 <strong>C’est gratuit</strong> et déjà beaucoup de bénéfices pour booster ta productivité.
          </>
        }
        action={
          withSubscribeButton ? (
            hasPermission('pro') ? (
              <Button className="btn w-full" disabled>
                Plus besoin
              </Button>
            ) : (
              <Button className="btn w-full" disabled>
                Plan actuel
              </Button>
            )
          ) : null
        }
      />
      <PricingItem
        plan="pro"
        highlighted
        price="300€"
        priceSmall="/mois"
        features={[
          { title: 'Membre d’une communauté de confiance', included: true },
          { title: '☕ Café virtuel pour networker', included: true },
          { title: 'Super slack', included: true },
          {
            title: (
              <>
                <strong className="text-primary">0 frais</strong> en cas de succès 🎉
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Trimoji <strong className="text-primary">Réduction de 100€/mois</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                <strong className="text-primary">4</strong> Ateliers <strong className="text-primary">gratuits</strong>/mois{' '}
                <strong className="text-primary">Valeur 150€</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Formation Kemra <strong className="text-primary">Réduction de 300€</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                Un audit de ton activité <strong className="text-primary">Valeur 150€</strong>
              </>
            ),
            included: true,
          },
        ]}
        warranty={
          <>
            💪 <strong>Garantie maximale</strong> Pas satisfait ? on te rembourse sans condition.
          </>
        }
        action={withSubscribeButton ? <SubscribeButton /> : null}
      />
    </div>
  );
};

export default Pricing;
