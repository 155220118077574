import classNames from 'classnames';
import React from 'react';
import Testimonial, { type TestimonialProps } from './Testimonial';
import TestimonialsRotating from './TestimonialsRotating';

type TestimonialListProps = {
  testimonials: TestimonialProps[];
  type?: TestimonialProps['type'] | 'rotating';
  align?: React.ComponentProps<typeof TestimonialsRotating>['align'];
  className?: string;
};

const TestimonialList: React.FC<TestimonialListProps> = ({ testimonials, type, align, className }) => {
  if (type === 'rotating') {
    return <TestimonialsRotating items={testimonials as any} align={align} />;
  }
  return (
    <section
      className={classNames(
        'mx-auto grid max-w-sm items-start gap-x-8 gap-y-12 sm:max-w-none sm:grid-cols-2 md:max-w-2xl lg:max-w-none lg:gap-12',
        className
      )}
    >
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} type={type} />
      ))}
    </section>
  );
};

export default TestimonialList;
