'use client';

import React from 'react';
import PlanBadge from './PlanBadge';

export default function PlanComparison({
  items,
}: {
  items: {
    plan: React.ComponentProps<typeof PlanBadge>['type'];
    label?: string;
    title: React.ReactNode;
    details?: React.ReactNode;
  }[];
}) {
  return (
    <div className="flex gap-4 [&>*]:flex-1">
      {items.map((item) => (
        <div key={item.plan}>
          <PlanBadge type={item.plan} label={item.label} />
          <div>
            <div className="mb-2">{item.title}</div>
            <div className="text-xs italic">{item.details}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
