import classNames from 'classnames';
import React, { ReactNode } from 'react';

type HeroProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  meta?: ReactNode;
  title: string | ReactNode;
  description: ReactNode;
  cta?: ReactNode;
};

const Hero: React.FC<HeroProps> = ({ meta, title, description, cta, className, ...props }) => {
  return (
    <section
      className={classNames(
        'mx-auto flex max-w-screen-xl flex-col items-center justify-center bg-white px-4 py-8 text-center dark:bg-gray-900 lg:px-12 lg:py-16',
        className
      )}
      {...props}
    >
      {meta && <div className="mb-7">{meta}</div>}
      <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl">{title}</h1>
      <p className="text-lg font-normal text-gray-500 dark:text-gray-400 sm:px-16 lg:text-xl xl:px-48">{description}</p>
      {cta && <div className="mt-8 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0">{cta}</div>}
    </section>
  );
};

export default Hero;
