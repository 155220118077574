'use client';

import classNames from 'classnames';

export default function PlanBadge({ type, label }: { type?: 'none' | 'standard' | 'pro'; label?: string }) {
  let planLabel = label || (type === 'pro' ? 'Pro' : type === 'standard' ? 'À la carte' : 'Sans HL');

  return (
    <span
      className={classNames('font-bold px-2 py-1 -rotate-3 inline-block shadow-sm', {
        'bg-primary text-gray-50': type === 'pro',
        'bg-secondary text-gray-50': type === 'standard',
        'bg-gray-500 text-gray-50': type === 'none',
      })}
    >
      {planLabel}
    </span>
  );
}
