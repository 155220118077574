import React from 'react';
import Header from 'modules/common/components/Header';
import Footer from 'modules/common/components/Footer';
import Head from 'modules/App/components/Head';

export default function HeaderLayout({ children, ...props }: React.ComponentProps<typeof Head>) {
  return (
    <>
      <Head {...props} />
      <Header />
      <main>{children}</main>

      <Footer />
    </>
  );
}
