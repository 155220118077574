'use client';

import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
// import Particles from './particles';

export type TestimonialsRotatingItemProps = {
  imageSrc: string;
  altText: string;
  quote: string | React.ReactNode;
  author: string;
  company: string;
};
export type TestimonialsRotatingProps = {
  items: TestimonialsRotatingItemProps[];
  align?: 'center' | 'left' | 'right';
};

const TestimonialsRotating: React.FC<TestimonialsRotatingProps> = ({ items, align = 'center' }) => {
  const [active, setActive] = useState<number>(0);
  const [autorotate, setAutorotate] = useState<boolean>(true);
  const [autorotateTiming] = useState<number>(7000);

  const testimonials = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : (active) => active + 1);
    }, autorotateTiming);
    return () => clearInterval(interval);
  }, [active, autorotate]);

  const heightFix = () => {
    if (testimonials.current && testimonials.current.parentElement)
      testimonials.current.parentElement.style.height = `${testimonials.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <div className="relative mx-auto max-w-3xl px-4 sm:px-6">
      {/* Particles animation */}
      {/* <div className="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-80 -mt-6">
            <Particles className="absolute inset-0 -z-10" quantity={10} staticity={40} /> 
          </div> */}

      {/* Carousel */}
      <div className="text-center">
        {/* Testimonial image */}
        <div className="relative h-48 [mask-image:_linear-gradient(0deg,transparent,theme(colors.white)_40%,theme(colors.white))]">
          <div className="pointer-events-none absolute left-1/2 top-0 -z-10 h-[480px] w-[480px] max-w-full -translate-x-1/2 rounded-full before:absolute before:inset-0 before:-z-20 before:rounded-full before:bg-gradient-to-b before:from-slate-400/20 before:to-transparent before:to-20% after:absolute after:inset-0 after:-z-20 after:m-px after:rounded-full after:bg-slate-900">
            {items.map((item, index) => (
              <Transition
                key={index}
                show={active === index}
                className="absolute inset-0 -z-10 h-full"
                enter="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                enterFrom="opacity-0 -rotate-[60deg]"
                enterTo="opacity-100 rotate-0"
                leave="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700"
                leaveFrom="opacity-100 rotate-0"
                leaveTo="opacity-0 rotate-[60deg]"
                beforeEnter={() => heightFix()}
              >
                <Image
                  className="relative left-1/2 top-11 -translate-x-1/2 rounded-full"
                  src={item.imageSrc}
                  width={100}
                  height={100}
                  alt={item.altText}
                />
              </Transition>
            ))}
          </div>
        </div>
        {/* Text */}
        <div className="mb-10 transition-all delay-300 duration-150 ease-in-out">
          <div className="relative flex flex-col" ref={testimonials}>
            {items.map((item, index) => (
              <Transition
                key={index}
                show={active === index}
                enter="transition ease-in-out duration-500 delay-200 order-first"
                enterFrom="opacity-0 -translate-x-4"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-out duration-300 delay-300 absolute"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-4"
                beforeEnter={() => heightFix()}
              >
                <div
                  className={classNames(
                    'bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 bg-clip-text text-left text-base font-bold text-transparent',
                    {
                      'text-center': align === 'center',
                      'text-left': align === 'left',
                      'text-right': align === 'right',
                    }
                  )}
                >
                  {item.quote}
                </div>
              </Transition>
            ))}
          </div>
        </div>
        {/* Buttons */}
        <div className="-m-1.5 flex justify-center overflow-x-auto">
          {items.map(
            (item, index) =>
              active === index && (
                <button
                  className={`btn-sm relative m-1.5 whitespace-nowrap py-1.5 text-xs text-slate-300 transition duration-150 ease-in-out [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:pointer-events-none before:absolute before:inset-0 before:rounded-full before:bg-slate-800/30 ${
                    active === index ? 'opacity-100' : 'opacity-30 hover:opacity-60'
                  }`}
                  key={index}
                  onClick={() => {
                    setActive(index);
                    setAutorotate(false);
                  }}
                >
                  <span className="relative">
                    <span className="text-slate-50">{item.author}</span> <span className="text-slate-600">-</span> <span>{item.company}</span>
                  </span>
                </button>
              )
          )}
          <button
            className={`btn-sm relative m-1.5 whitespace-nowrap py-1.5 text-xs text-slate-300 opacity-60 transition duration-150 ease-in-out [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:pointer-events-none before:absolute before:inset-0 before:rounded-full before:bg-slate-800/30 hover:opacity-80`}
            onClick={() => {
              setActive(active === items.length - 1 ? 0 : active + 1);
              if (active === items.length - 1) {
                setAutorotate(false);
              }
            }}
          >
            <span className="relative">Non, c’est pas moi</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsRotating;
