'use client';

import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ToolBadge from './ToolBadge';

export default function RotatingFeatures({
  title,
  subtitle,
  items,
  className,
}: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  className?: string;
  items: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    details?: React.ReactNode;
    id: string;
    type?: React.ComponentProps<typeof ToolBadge>['type'];
    icon?: React.ReactNode;
  }[];
}) {
  const [tab, setTab] = useState<number>(1);

  const tabs = useRef<HTMLDivElement>(null);

  const heightFix = () => {
    if (tabs.current && tabs.current.parentElement) tabs.current.parentElement.style.height = `${tabs.current.clientHeight}px`;
  };

  useEffect(() => {
    heightFix();
  }, []);

  return (
    <div className={classNames('lg:grid lg:grid-cols-12 lg:gap-6', className)}>
      {/* Content */}
      <div className="max-w-xl lg:max-w-none lg:w-full mx-auto lg:col-span-7 lg:mt-6" data-aos="fade-right">
        <div className="lg:pr-12 xl:pr-16 mb-8">
          <h3 className="h4 mb-3">{title}</h3>
          <p className="text-base text-gray-600">{subtitle}</p>
        </div>
        {/* Tabs buttons */}
        <div className="mb-8 lg:mb-0 flex flex-col items-stretch">
          {items.map((item, index) => (
            <React.Fragment key={`button_fragment_${item.id}`}>
              <button
                key={`button_${item.id}`}
                className={`text-left flex items-center text-lg p-5 lg:rounded lg:border transition duration-300 ease-in-out mb-3 relative max-lg:bg-gray-200 max-lg:border-transparent ${
                  tab !== index + 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setTab(index + 1);
                }}
              >
                {tab === index + 1 && (
                  <>
                    <div className="hidden lg:block absolute right-[-25px] w-0 h-0 transition duration-300 ease-in-out border-t-[15px] border-t-transparent border-l-[25px] border-l-gray-200 border-b-[15px] border-b-transparent"></div>
                    <div className="block lg:hidden absolute bottom-[-25px] left-[50%] transform -translate-x-1/2 w-0 h-0 transition duration-300 ease-in-out border-l-[15px] border-l-transparent border-t-[25px] border-t-gray-200 border-r-[15px] border-r-transparent"></div>
                  </>
                )}
                <div className="flex-1">
                  <div className="font-bold leading-snug tracking-tight mb-1">{item.title}</div>
                  <div className="text-gray-600 text-base">{item.subtitle}</div>
                  {item.type && <ToolBadge type={item.type} />}
                </div>
                <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                  {item.icon || (
                    <svg className="w-3 h-3 fill-current" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.953 4.29a.5.5 0 00-.454-.292H6.14L6.984.62A.5.5 0 006.12.173l-6 7a.5.5 0 00.379.825h5.359l-.844 3.38a.5.5 0 00.864.445l6-7a.5.5 0 00.075-.534z" />
                    </svg>
                  )}
                </div>
              </button>
              {tab === index + 1 && item.details && <div className="lg:hidden p-5 mb-12">{item.details}</div>}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Tabs items */}
      <div className="hidden max-w-xl lg:max-w-none lg:w-full mx-auto lg:col-span-5 mb-8 lg:mb-0 lg:order-1 lg:flex items-center">
        <div className="transition-all w-full">
          <div
            className="w-full relative flex flex-col items-center justify-center text-center lg:text-right transition-all"
            data-aos="zoom-y-out"
            ref={tabs}
          >
            {items.map((item, index) => (
              <React.Fragment key={`details_${item.id}`}>
                <Transition
                  show={tab === index + 1}
                  className="w-full"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterFrom="opacity-0 translate-y-16"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-16"
                  beforeEnter={() => heightFix()}
                  unmount={false}
                >
                  <div
                    className="w-full relative inline-flex flex-col border-gray-200 border-2 rounded-md p-5"
                    style={{ marginTop: `${index * 20}px` }}
                  >
                    {item.details}
                  </div>
                </Transition>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
