/* This example requires Tailwind CSS v2.0+ */
import { useAuthModal } from 'modules/auth';
import Button from 'modules/common/components/Button';
import Image from 'next/image';

export default function Example() {
  const { toggleModal } = useAuthModal();
  return (
    <div className="bg-gray-50" id="screenshot">
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-3 sm:pb-5">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-20 sm:px-6">
            <div className="text-center">
              <h2 className="mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl">
                <span className="block">Ok, ça ressemble à quoi ?</span>
              </h2>
              <p className="text-center">
                <strong>Multipliez</strong> et <strong>améliorez</strong> vos placements.
              </p>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-dark" />
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <Image
              width={1475}
              height={1082}
              className="relative rounded-lg shadow-lg"
              src="/images/marketing/hrp-screenshot-missions.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>

      <div className="bg-dark pb-16">
        <div className="max-w-md mx-auto sm:flex sm:justify-center text-center">
          <Button className="btn btn-primary" onClick={toggleModal}>
            J’accède à la plateforme gratuitement
          </Button>
          {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Live demo
                </a>
              </div> */}
        </div>
        {/* <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">
            Trusted by over 26,000 forward-thinking companies
          </h2>
          <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg" alt="StaticKit" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img className="h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor" />
            </div>
            <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
              <img className="h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
