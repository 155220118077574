import React from 'react';
import classNames from 'classnames';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  stopPropagation?: boolean;
  badge?: boolean | string;
  badgeClassName?: string;
};

const Button: React.FC<ButtonProps> = ({
  className,
  type = 'button',
  children,
  onClick: onExternalClick,
  loading,
  badge,
  badgeClassName,
  stopPropagation = true,
  ...props
}) => {
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onExternalClick) {
      return;
    }
    if (stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (props.disabled) {
      return;
    }

    onExternalClick?.(e);
  };

  return (
    <button onClick={onClick} type={type} className={classNames('btn', { relative: !!badge }, className)} {...props}>
      {badge && (
        <div
          className={classNames(
            'badge badge-sm absolute right-[-5px] top-[-5px]',
            {
              'badge-accent animate-[ping_1s_ease-in-out_infinite]': !badgeClassName,
            },
            badgeClassName
          )}
        >
          {typeof badge === 'string' ? badge : ''}
        </div>
      )}
      {loading && <span className="loading loading-xs"></span>}
      {children}
    </button>
  );
};

export default Button;
