import React from 'react';

interface IProps {
  value: number;
  percent?: boolean;
  locale?: string;
  precision?: number;
  options?: any;
  className?: string;
}

export const formatNumber = ({ value, locale = 'en-US', percent = false, precision = 2, options = {} }: any) => {
  let enhancedOptions = {
    ...options,
    maximumFractionDigits: precision,
  };

  if (!value && typeof value !== 'number') {
    return '-';
  }

  if (percent) {
    enhancedOptions = {
      ...enhancedOptions,
      style: 'percent',
    };
  }

  return value.toLocaleString(locale, enhancedOptions);
};
export const formatCurrency = ({
  value,
  locale = 'en-US',
  precision = 0,
  compact = false,
}: {
  value: number;
  locale?: string;
  precision?: number;
  compact?: boolean;
}) => {
  return (value || 0)
    .toLocaleString(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
      style: 'currency',
      unitDisplay: 'narrow',
      currency: locale === 'fr' ? 'EUR' : 'USD',
      ...(compact ? { notation: 'compact', compactDisplay: 'short' } : {}),
    })
    .replace(/\Dk.*€/, 'k€');
};

export const formatSize = (bytes?: number | React.ReactNode) => {
  if (typeof bytes !== 'number') {
    return { value: bytes, unit: 'MB', string: `${bytes} MB` };
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0 || !bytes) return { value: 0, unit: 'Byte', string: '0 Byte' };
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`, 10);
  const value = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  const unit = sizes[i];
  return { value, unit, string: value + ' ' + unit };
};

const NumberComponent = ({ value, locale = 'en-US', percent = false, precision = 2, options = {}, ...props }: IProps) => {
  const numberStr = formatNumber({ value, locale, percent, precision, options });

  return <span {...props}>{numberStr}</span>;
};

export default NumberComponent;
