import classNames from 'classnames';
import React from 'react';

type HighlightProps = React.HTMLAttributes<HTMLSpanElement> & {
  // TODO
};

const Highlight: React.FC<HighlightProps> = ({ children, className, ...props }) => {
  return (
    <span className={classNames('inline-block whitespace-nowrap bg-primary px-0.5 font-bold text-white', className)} {...props}>
      {children}
    </span>
  );
};

export default Highlight;
